import { Theme } from '@emotion/react';

const colors = {
  primary: {
    orange: '#FD7500',
    darkOrange: '#DA6500'
  },
  secondary: {
    black: '#000000',
    transparentBlack: '#00000029',
    white: '#FFFFFF',
    paleLightGray: '#FAFAFA',
    paleGray: '#F2F2F2',
    lightGray: '#E7E7E76E',
    gray: '#929292',
    darkGray: '#6E6E6E73',
    graphite: '#5E5E5E',
    darkPaleGray: '#DEDEDE',
    footerGray: '#918F8E',
    highlightText: '#FD75001c'
  }
};

export const theme: Theme = {
  colors,
  breakpoints: {
    xs: '@media only screen and (max-width: 425px)',
    sm: '@media only screen and (max-width: 600px)',
    md: '@media only screen and (max-width: 768px)',
    lg: '@media only screen and (max-width: 1023px)',
    xl: '@media only screen and (min-width: 1024px) '
  },
  fonts: {
    montserrat: 'var(--font-montserrat), serif',
    nunitoSans: 'var(--font-nunito-sans), serif',
    libre: 'Libre Baskerville, serif'
  },

  boxShadow: `0px 6px 13px ${colors.secondary.transparentBlack}`,

  maxWidth: {
    xs: '279px',
    sm: '450px',
    md: '800px',
    lg: '1024px',
    xl: '1200px'
  }
};

type PrimaryColors = 'orange' | 'darkOrange';

type SecondaryColors =
  | 'white'
  | 'lightGray'
  | 'gray'
  | 'darkGray'
  | 'paleGray'
  | 'black'
  | 'transparentBlack'
  | 'graphite'
  | 'darkPaleGray'
  | 'paleLightGray'
  | 'footerGray'
  | 'highlightText';

declare module '@emotion/react' {
  export interface Theme {
    colors: {
      primary: {
        [x in PrimaryColors]: string;
      };
      secondary: {
        [x in SecondaryColors]: string;
      };
    };
    breakpoints: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    fonts: {
      libre: string;
      montserrat: string;
      nunitoSans: string;
    };
    boxShadow: string;
    maxWidth: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }
}
