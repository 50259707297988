import { AppProps } from 'next/app';
import Router from 'next/router';

import { ThemeProvider } from '@emotion/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import NProgress from 'nprogress';
import { montserrat, nunitoSans } from 'styles/fonts';

import { theme } from 'theme';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '../styles/globals.css';
import * as S from 'styles/Main.styles';

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS

// NProgress - used for transition between route changes
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <S.Main className={`${montserrat.variable} ${nunitoSans.variable}`}>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </S.Main>
  );
};

export default MyApp;
